<template>
  <div class="vipItem">
    <swiper
      class="vipSwiper"
      ref="vipSwiper"
      :options="swiperOption"
      v-if="vipList.length > 0"
    >
      <swiper-slide
        class="swiperSlide"
        v-for="item in vipList"
        :key="item.productID"
        :class="{ activeVip: item.productID == selectedItem.productID }"
      >
        <div class="vipCard">
          <img
            ref="vipImg"
            class="vipImg"
            :src="defaultImg"
            :addres="item.bgImg"
            alt=""
            @load.once="imgLoad($event)"
            @error.once="imgError($event)"
            v-show="item.productID == selectedItem.productID"
          />
          <img
            ref="defaultBg"
            class="defaultBg"
            :src="defaultImg"
            :addres="item.bgImgType1"
            alt=""
            @load.once="imgLoad($event)"
            @error.once="imgError($event)"
          />
        </div>

        <div
          v-if="
            isNewUser &&
            item.productID == '62e8a2cc0085daa47f241581' &&
            item.productID == selectedItem.productID
          "
          class="countdown"
        >
          <span>{{ timeStr }}</span>
        </div>
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-if="vipList.length != 1"
      ></div>
    </swiper>
    <div class="vipDesc">
      会员特权 <span>{{ selectedItem.privilegeDesc }}</span>
    </div>
    <div class="vipRights">
      <div
        class="rightsItem"
        v-for="(rItem, rIndex) in selectedItem.privilege"
        :key="rIndex"
      >
        <ImgDecypt class="rightsImg" :src="rItem.img" :key="rItem.img" />
        <div class="privilegeName">{{ rItem.privilegeName }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="payQuestionTitle">帮助中心</div>
    <div class="payQuestion">
      <router-link tag="div" to="/duplicatePay" class="questionItem">
        <div>如果重复购买了会员怎么办？</div>
        <svg-icon class="cellIcon" iconClass="rightArrow" />
      </router-link>
      <router-link tag="div" to="/notVip" class="questionItem">
        <div>支付成功了，但是没有显示会员卡？</div>
        <svg-icon class="cellIcon" iconClass="rightArrow" />
      </router-link>
      <router-link tag="div" to="/payfail" class="questionItem">
        <div>通常会有哪些原因造成支付失败？</div>
        <svg-icon class="cellIcon" iconClass="rightArrow" />
      </router-link>
    </div>
    <div class="payBox">
      <div class="payBtn" @click="showPay">
        ¥{{ parseInt(selectedItem.discountedPrice / 10 || 0) }}/立即开通
      </div>
      <div class="payTip">
        支付中如有问题，请咨询<router-link tag="span" to="/customerService"
          >在线客服</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { queryVipList, queryTiroCountdown } from "@/api/mine";
import { imgDecyptApi } from "@/api/app";
import "swiper/dist/css/swiper.css";
import ImgDecypt from "@/components/ImgDecypt";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Toast } from "vant";
export default {
  components: {
    swiper,
    swiperSlide,
    ImgDecypt,
  },
  // ...mapGetters({
  //     imgApi: "imgApi",
  // }),
  data() {
    let _this = this;
    return {
      // 轮播配置
      swiperOption: {
        direction: "horizontal", // 水平切换选项
        loop: true, // 循环模式
        slidesPerView: "auto", // 默认一个屏幕显示几张图
        centeredSlides: true, // 每屏，居中显示
        // initialSlide: 1,
        // observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: () => {
            this.activeIndex = this.$refs.vipSwiper.swiper
              ? this.$refs.vipSwiper.swiper.realIndex
              : 0;
            this.selectedItem = this.vipList[this.activeIndex];
            // console.log(this.activeIndex)
            this.changItem();
            if (this.$refs.vipSwiper.swiper) {
              this.$refs.vipSwiper.swiper.slideToLoop(
                this.$refs.vipSwiper.swiper.realIndex,
                0,
                false
              );
            }

            // this.swiperLoop()
          },
          click: function () {
            _this.handleInvite(this);
          },
        },
      },
      defaultImg: require("@/assets/png/place.png"),
      vipList: [], // 会员卡列表
      activeIndex: 0, // 选中会员卡下标
      selectedItem: {}, // 选中
      dcInfo: {}, // 代充信息
      countdownSec: 0, // 新手倒计时时间 单位秒
      hr: "", // 新手倒计时-小时
      min: "", // 新手倒计时-分钟
      sec: "", // 新手倒计时-秒
      timeStr: "",
      countdownFnc: "",
      cardList: [],
      isNewUser: false, //是否是新人
    };
  },
  created() {
    this.queryTiroCountdown();
    this.queryVipList();
  },
  mounted() {},
  methods: {
    showPay() {
      this.$emit("showPay");
    },
    handleBgImg(list) {
      let imgApi = this.$store.getters.imgApi;
      this.vipList = [];
      list.forEach(async (item, index) => {
        let imgUrl = await imgDecyptApi(imgApi + "/" + item.bgImg);
        let bgImgType1 = await imgDecyptApi(imgApi + "/" + item.bgImgType1);
        item.bgImg = imgUrl;
        item.bgImgType1 = bgImgType1;
        if (index == list.length - 1) {
          this.vipList = [...list];
          this.$store.commit("app/SET_LOADING", false);
        }
      });
    },
    // 点击会员卡
    handleInvite(vm) {
      this.$refs.vipSwiper.swiper.slideTo(vm.clickedIndex);
      // return
      let index = vm.clickedIndex - vm.activeIndex + vm.realIndex;
      if (index === this.vipList.length) {
        index = 0;
      } else if (index === this.vipList.length + 1) {
        index = 1;
      } else if (index === this.vipList.length + 2) {
        index = 2;
      }
    },
    // 查询新手优惠时间
    async queryTiroCountdown() {
      let res = await this.$Api(queryTiroCountdown);
      if (res && res.code === 200) {
        this.countdownSec = res.data.countdownSec;
        this.countdown(this.countdownSec);
      }
    },
    // 查询会员卡列表
    async queryVipList() {
      this.$store.commit("app/SET_LOADING", true);
      // try {
      let res = await this.$Api(queryVipList);
      this.$store.commit("app/SET_LOADING", false);
      if (res && res.code == 200) {
        this.isNewUser = res.data.isNewUser;
        this.cardList = [];
        res.data.list.forEach((i) => {
          if (
            i.position === "会员卡" ||
            i.position === "商家卡" ||
            i.position == "博主卡"
          ) {
            // let arr = [...(i.list)];
            this.cardList = this.cardList.concat(i.list);
            let cid = this.$route.query.cid;
            if (cid) {
              this.cardList.forEach((item, index) => {
                if (item.productID == cid) {
                  let newArr = this.cardList.splice(index);
                  this.vipList = [...newArr, ...this.cardList];
                  // this.handleBgImg([...newArr, ...arr])
                }
              });
            } else {
              // this.handleBgImg(arr)
              this.vipList = [...this.cardList];
            }
          }
        });
        this.dcInfo = res.data.daichong;
        setTimeout(() => {
          this.swiperLoop();
        }, 200);
        // console.log(this.vipList)
      }
      // } catch (error) {
      //     this.$store.commit('app/SET_LOADING', false);
      //     Toast("获取会员卡信息失败，请退出重试")
      // }
      // setTimeout(() => {
      //     this.$refs.vipSwiper.swiper.slideTo(3);
      // }, 500)
      // this.$refs.vipSwiper.swiper.slideTo(3);
    },
    // 选中会员卡
    changItem() {
      let payData = {
        rechargeList: this.selectedItem.rchgType, // 支付方式列表
        dcInfo: this.dcInfo, // 代充信息
        popType: "vip", // 弹窗类型
        productType: this.selectedItem.productType, // 商品类型
        productID: this.selectedItem.productID, // 商品ID
        dcType: 1, // 代充商品类型
        buyType: 4, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.selectedItem.discountedPrice / 10, // 充值金额
        isAmountPay: this.selectedItem.isAmountPay, // 是否支持金币兑换
      };
      this.$emit("changItem", payData);
    },

    // 倒计时
    countdown(timer) {
      if (timer <= 0) return;
      // 相差的毫秒数
      const msec = timer;
      // 计算时分秒数
      let hr = parseInt(msec / 60 / 60);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `限时 ${hr}:${min}:${sec}`;
      // 控制台打印
      // console.log(` ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      this.countdownFnc = setTimeout(() => {
        timer--;
        this.countdown(timer);
      }, 1000);
    },
    async imgLoad(event) {
      let imgApi = this.$store.getters.imgApi;
      let url = event.target.getAttribute("addres");
      let dom = event.target;
      dom.src = await imgDecyptApi(imgApi + "/" + url);
    },
    imgError(event) {
      let dom = event.target;
      dom.src = this.defaultImg;
    },
    swiperLoop() {
      this.$nextTick(async () => {
        let imgApi = this.$store.getters.imgApi;

        //由于loop设置为true，所以会导致最后一张图无法显示并且无法点击
        //处理最后一张图开始(实际为第一张图)
        let dom = document.getElementsByClassName("defaultBg"); //获取最后一轮播的div
        // console.log(dom[4].src)
        for (let i = 0; i < dom.length; i++) {
          let url = dom[i].getAttribute("addres");
          let notImg = dom[i].src.indexOf("data:image/") >= 0;
          if (notImg) {
            imgDecyptApi(imgApi + "/" + url).then((res) => {
              dom[i].src = res;
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vipItem {
  color: #000;
  height: calc(100vh - 166px);
  overflow-y: auto;
  padding: 0 16px;
  box-sizing: border-box;

  .title {
    font-size: 18px;
    margin: 18px 0;
  }

  .vipSwiper {
    height: 166px;
    margin-top: 18px;

    .swiperSlide {
      width: 105px;
      margin: 0 4px;
      display: flex;
      align-items: flex-end;

      .vipCard {
        width: 100%;
        height: 145px;

        .vipImg {
          width: 100%;
          height: 100%;
        }
        .defaultBg {
          width: 100%;
          height: 100%;
        }
      }

      .countdown {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: center;
        padding-bottom: 10px;

        span {
          text-align: center;
          width: 95px;
          background: rgb(0, 255, 255);
          border-radius: 8px;
          font-size: 10px;
          //border: solid 3px rgb(85,0,185);
          box-shadow: 0 0 3px 0px rgb(85, 0, 185);
        }
      }
    }

    .activeVip {
      width: 117px;

      .vipCard {
        width: 100%;
        height: 100%;

        .vipImg {
          width: 100%;
          height: 100%;
        }
        .defaultBg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .vipDesc {
    font-size: 16px;
    padding-top: 18px;
    margin-top: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    border-top: 1px solid #e6e6e6;

    span {
      font-size: 12px;
      margin-left: 12px;
      color: #94d6da;
      flex: 2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .vipRights {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 12px;
    grid-column-gap: 40px;
    margin: 12px 0;

    .rightsItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .rightsImg {
        width: 30px;
        height: 30px;
        margin-bottom: 6px;

        /deep/ .vanImage {
          background: #00000000 !important;
        }
      }
      .privilegeName {
        color: #333;
        font-size: 12px;
      }
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #e6e6e6;
  }
  .payBox {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
  }
  .payBtn {
    width: 327px;
    height: 45px;
    border-radius: 45px;
    font-size: 18px;
    margin: 23px auto 12px;
    color: #424242;
    background: #fbd13d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .payTip {
    font-size: 12px;
    color: #8e8d9b;
    text-align: center;
    span {
      color: #fbd13d;
    }
  }
  .payQuestionTitle {
    font-size: 16px;
    margin: 18px 0 12px;
  }
  .payQuestion {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 0 160px;
    padding: 0 12px;
    box-sizing: border-box;

    .questionItem {
      // padding: 6px 12px;
      height: 44px;
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
    }
    .questionItem:last-child {
      border: none;
    }
  }
}
</style>
