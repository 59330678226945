<template>
  <div class="goldenBeans">
    <div class="title">金币余额</div>
    <div class="balanceBox">
      <div class="balance">
        {{ walletInfo.amount + walletInfo.income || 0 }}
      </div>
      <div class="unit">/金币</div>
    </div>
    <div class="title">在线充值</div>
    <div class="payAmountBox">
      <div
        :class="
          selectIndex === index ? 'payAmountItem' : 'defaultPayAmountItem'
        "
        v-for="(item, index) in amountList"
        :key="item.id"
        @click="selectAmount(index)"
      >
        <div class="amountBox">
          <svg-icon class="goldIcon" iconClass="rechargeGoldIcon" />
          <div class="amountInfo">
            <div class="goldBean">{{ item.amount }}金币</div>
            <div class="yuan">{{ item.amount / 10 }}RMB</div>
          </div>
        </div>
        <div class="desc" v-if="item.firstGiveGold">
          首充多送{{ item.firstGiveGold || 0 }}
        </div>
      </div>
    </div>
    <div class="tip">10金币/1元</div>
    <div class="payBtn" @click="showPay">¥{{ currentAmount }} / 立即充值</div>
    <div class="kfTip">支付中如有问题，请咨询<span>在线客服</span></div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { queryCurrencys } from "@/api/mine";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      walletInfo: "walletInfo",
    }),
  },
  data() {
    return {
      selectIndex: 0, // 选中下标
      amountList: [], // 金额列表
      payMethods: [], // 支付方式
      dcInfo: {}, // 代充信息
      currentAmount: 0, // 当前金额
    };
  },
  created() {
    this.$store.dispatch("user/getWallet");
    this.queryCurrencys();
  },
  methods: {
    // 查询金额
    async queryCurrencys() {
      this.$store.commit("app/SET_LOADING", true);
      try {
        let ret = await this.$Api(queryCurrencys, {
          type: "1",
        });
        if (ret && ret.code === 200) {
          this.amountList = ret.data.list;
          this.changItem();
          // this.payMethods = [...this.amountList[this.selectIndex].rchgType];
          this.dcInfo = JSON.parse(JSON.stringify(ret.data.daichong));
        } else if (ret.tip) {
          Toast(ret.tip);
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("查询失败，请稍后再试");
      }
    },
    // 选择金额
    selectAmount(index) {
      this.selectIndex = index;
      this.payMethods = [...this.amountList[index].rchgType];

      this.changItem();
    },
    // 选择金币
    changItem() {
      this.currentAmount = this.amountList[this.selectIndex].amount
        ? this.amountList[this.selectIndex].amount / 10
        : 0;
      let payData = {
        rechargeList: [...this.amountList[this.selectIndex].rchgType], // 支付方式列表
        dcInfo: this.dcInfo, // 代充信息
        popType: "gold", // 弹窗类型
        productType: undefined, // 商品类型
        productID: this.amountList[this.selectIndex].id, // 商品ID
        dcType: 0, // 代充商品类型 0-购买金币 1-是会员 2-是果币  3-是裸聊
        buyType: 1, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.amountList[this.selectIndex].money / 100, // 充值金额
      };
      this.$emit("changItem", payData);
    },
    showPay() {
      this.$emit("showPay");
    },
  },
};
</script>

<style lang="scss" scoped>
.goldenBeans {
  height: calc(100vh - 166px);
  overflow-y: auto;
  padding: 0 16px;
  box-sizing: border-box;
  .title {
    font-size: 16px;
    margin: 18px 0 12px;
    color: #333;
  }

  .balanceBox {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid rgba(230, 230, 230, 1);

    .balance {
      font-size: 30px;
      margin-right: 12px;
    }

    .unit {
      font-size: 16px;
      color: #333;
    }
  }

  .payAmountBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 5px;
    margin-top: 20px;

    .amountBox {
      //   display: flex;
      //   align-items: center;
      //   height: 48px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;

      .goldIcon {
        width: 48px;
        height: 48px;
        margin: 0 auto 5px;
        // margin-left: 9.3px;
      }

      .amountInfo {
        text-align: center;

        .goldBean {
          font-size: 14px;
          margin-bottom: 4px;
        }

        .yuan {
          font-size: 12px;
          color: #999;
        }
      }
    }

    .defaultPayAmountItem {
      width: 109px;
      height: 128px;
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      padding-top: 23px;
      box-sizing: border-box;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      justify-content: center;
      position: relative;
      //   .line {
      //     width: 100%;
      //     height: 1px;
      //     background-image: linear-gradient(
      //       to right,
      //       #44260c00,
      //       #716f81,
      //       #44260c00
      //     );
      //   }
      .desc {
        padding: 0 8px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        border-radius: 12px 0;
        color: #333;
        background: #e2e2e6;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -8px;
        left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .payAmountItem {
      width: 109px;
      height: 128px;
      border-radius: 12px;
      border: 1px solid #fbd13d;
      // background: #e7feff;
      background: rgba(255, 97, 62, 0.1);
      padding-top: 23px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      position: relative;
      //   .amountBox {
      //     height: 50px;
      //   }
      //   .line {
      //     width: 100%;
      //     height: 1px;
      //     background-image: linear-gradient(
      //       to right,
      //       #44260c00,
      //       #44260c,
      //       #44260c00
      //     );
      //   }

      .desc {
        padding: 0 8px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        border-radius: 12px 0;
        color: #333;
        background: #fbcf17;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -8px;
        left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .tip {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin: 18px 0 43px;
  }
  .payBtn {
    width: 327px;
    height: 45px;
    border-radius: 45px;
    font-size: 18px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    background: #fbcf17;
  }
  .kfTip {
    text-align: center;
    font-size: 12px;
    color: #8e8d9b;
    margin: 12px 0 40px;
    span {
      color: #fbd13d;
    }
  }
}
</style>
