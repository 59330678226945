<template>
  <div class="recharge">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">会员中心</div>
      <router-link tag="div" to="/bill" class="rightBtn">充值明细</router-link>
    </div>
    <div class="userBox">
      <ImgDecypt
        class="avatarImg"
        :src="userInfo.portrait"
        :key="userInfo.portrait"
        round
      />
      <div class="userInfoBox">
        <div class="uName">
          {{ userInfo.name || "未知" }}
          <svg-icon
            class="vipIcon"
            iconClass="vip"
            v-if="userInfo.isVip && checkTime(userInfo.videoFreeExpiration)"
          />
        </div>
        <div
          class="isVip"
          v-if="userInfo.isVip && checkTime(userInfo.videoFreeExpiration)"
        >
          {{ handleVipText() }}
        </div>
        <div class="isVip" v-else>会员享受更多专属特权</div>
      </div>
    </div>
    <van-tabs
      class="vipTab"
      v-model="activeTab"
      animated
      swipeable
      title-inactive-color="#666"
      title-active-color="#FEB658"
      background="#E5E5E5"
      color="#7145e7"
      @change="changeTab"
    >
      <van-tab name="vip">
        <div slot="title" class="leftTab">
          <svg-icon
            class="tabIcon"
            :iconClass="activeTab == 'vip' ? 'activeVipTabIcon' : 'vipTabIcon'"
          />影片会员
        </div>
        <VipItem @showPay="showPay" @changItem="changItem"></VipItem>
      </van-tab>
      <van-tab name="gold">
        <div slot="title" class="rightTab">
          <svg-icon
            class="tabIcon"
            :iconClass="
              activeTab == 'gold' ? 'activeGoldTabIcon' : 'goldTabIcon'
            "
          />购买金币
        </div>
        <GoldenBeans @showPay="showPay" @changItem="changItem"></GoldenBeans>
      </van-tab>
    </van-tabs>

    <!-- 支付方式弹窗 -->
    <van-popup class="rechargePop" v-model="isRecharge" position="bottom">
      <div class="popBox">
        <div class="rechargeTitle">选择支付方式</div>
        <van-radio-group
          v-model="activeItem"
          direction="horizontal"
          class="rechargeList"
        >
          <div
            v-for="item in rechargeList"
            :key="item.productID"
            @click.stop="selectPay(item)"
          >
            <div class="rechargeItem" v-if="item.type !== 'daichong'">
              <svg-icon class="payIcon" :iconClass="item.type"></svg-icon>
              <div class="payText">{{ item.typeName }}</div>
              <van-radio
                checked-color="#FBD13D"
                icon-size="14px"
                :name="item.type"
              ></van-radio>
            </div>
            <!-- 代充 -->
            <div
              v-if="
                dcInfo.traders &&
                dcInfo.traders.length > 0 &&
                item.type === 'daichong'
              "
            >
              <div
                v-for="dcItem in dcInfo.traders[0].payInfos"
                :key="dcItem.payMethod"
                @click="selectDcPay(item, dcItem)"
              >
                <div class="rechargeItem" v-if="item.type == 'daichong'">
                  <div class="dcIconBox">
                    <svg-icon class="official" iconClass="official"></svg-icon>
                    <svg-icon
                      class="payIcon"
                      :iconClass="getSvgPayIcon(dcItem.payMethod)"
                    >
                    </svg-icon>
                  </div>
                  <div class="payText">
                    {{ getPayName(dcItem.payMethod) }}<span>（人工充值）</span>
                  </div>
                  <van-radio
                    checked-color="#7145e7"
                    icon-size="14px"
                    :name="'dc' + dcItem.payMethod"
                  >
                  </van-radio>
                </div>
              </div>
            </div>
          </div>
          <div
            class="rechargeItem"
            v-if="popType == 'vip' && isAmountPay"
            @click="selectGoldPay"
          >
            <svg-icon class="payIcon" iconClass="gold"></svg-icon>
            <div class="payText">金币</div>
            <van-radio
              checked-color="#fe7f0f"
              icon-size="16px"
              name="gold"
            ></van-radio>
          </div>
        </van-radio-group>
        <div class="payTipText">支付小贴士</div>
        <div class="payTip">
          1、跳转后请及时付款，超时支付无法到账，要重新发起
          <br />2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单
        </div>
        <!-- <div class="payTip">2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单</div> -->
        <div class="customerService">
          支付中如有问题，请咨询<router-link tag="span" to="/customerService"
            >在线客服</router-link
          >
        </div>
        <div class="payBtn" @click="toPay">立即支付</div>
        <!-- <div class=""></div> -->
      </div>
    </van-popup>

    <van-popup class="payTipPop" v-model="showPayTip" @close="closePayTip">
      <div class="payTipBox">
        <div class="payTipTitle">温馨提示</div>
        <div class="payTipContent">
          1.订单支付后，一般会在3-5分钟内到账，如超时未到账，请联系在线客服为您处理。<br />
          2.受特殊行业限制，如支付失败可尝试重新发起订单，系统将会随机切换备用的支付通道。<br />
          3.本APP有稳定的广告收益，产品稳定安全，请放心充值使用，如遇报毒提示忽略即可。
        </div>
        <div class="payTipImg"></div>
        <!-- <div class="payQuestion" @click="toPayQuestion">
          <div class="payQuestionText">支付遇到问题</div>
          <svg-icon class="payTipArrow" iconClass="settingArrow"></svg-icon>
        </div> -->
        <!-- <div class="payTipLine"></div> -->
        <div class="payTipBtn" @click="payTipBtn">
          {{ payTipType == "tip" ? "我知道了" : "立即支付" }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Tab, Tabs, Popup, RadioGroup, Radio, Toast } from "vant";
import { goldExchange, getPay } from "@/api/mine";
import { getLocalItem } from "@/utils/longStorage";
import ImgDecypt from "@/components/ImgDecypt";
import VipItem from "./VipItem";
import GoldenBeans from "./GoldenBeans";
export default {
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  components: {
    VipItem,
    GoldenBeans,
    ImgDecypt,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  data() {
    return {
      activeTab: "vip",
      isRecharge: false, // 充值弹窗
      rechargeList: [], // 支付方式列表
      activeItem: "", // 支付方式选中
      dcInfo: "", // 代充信息
      dcType: 1, // 代充商品类型  会员-1 金币-0
      popType: "vip", // 弹窗类型 会员卡-vip  金币-gold
      payMethodType: "dc", // 支付方式类型  支付渠道-channel  代充-dc  金币兑换-gold
      productType: "", // 商品类型
      productID: "", // 商品ID
      buyType: "", // 商品类型
      discountedPrice: 0, // 充值金额
      payInfo: {}, // 支付渠道信息
      rchgType: {}, // 充值类型
      dcPayInfo: {}, // 代充支付信息
      isAmountPay: false, // 是否支持金币兑换
      showPayTip: sessionStorage.getItem("firstShowTip") == 0 ? false : false, // 支付提示弹窗
      payTipType: "tip", // 支付弹窗类型
      payUrl: "",
    };
  },
  created() {
    this.activeTab = this.$route.query.t ? this.$route.query.t : "vip";
    // console.log(this.userInfo)
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    showPay() {
      this.isRecharge = true;
    },
    toPay() {
      if (this.payMethodType == "gold") {
        this.goldExchange();
      } else if (this.payMethodType == "channel") {
        this.channelPay();
      } else if (this.payMethodType == "dc") {
        this.dcToPay();
      }
    },
    changeTab(i) {
      this.$router.replace(`/rechargePage?t=${i}`);
    },
    /**
     * @description 显示支付方式弹窗
     * @param rechargeList - 支付方式列表
     * @param dcInfo - 代充信息
     * @param popType - 弹窗类型  会员卡-vip  金币-gold
     */
    changItem(payData) {
      this.rechargeList = payData.rechargeList;
      this.dcInfo = payData.dcInfo;
      this.popType = payData.popType;
      this.dcType = payData.dcType;
      this.productType = payData.productType;
      this.productID = payData.productID;
      this.discountedPrice = payData.discountedPrice;
      this.buyType = payData.buyType;
      this.isAmountPay = payData.isAmountPay;
      // console.log(this.rechargeList)
      let dcArr = this.rechargeList.filter((i) => {
        return i.type == "daichong";
      });
      let channel = this.rechargeList.filter((i) => {
        return i.type != "daichong";
      });
      if (channel.length > 0) {
        this.selectPay(this.rechargeList[0]);
      } else if (
        dcArr.length > 0 &&
        this.dcInfo.traders.length > 0 &&
        this.dcInfo.traders[0].payInfos.length > 0
      ) {
        let arr = this.rechargeList.filter((i) => {
          return i.type == "daichong";
        });
        // console.log(arr, "---------")
        this.selectDcPay(arr[0], this.dcInfo.traders[0].payInfos[0]);
      } else {
        this.selectGoldPay();
      }
      // this.isRecharge = true;
    },
    // 选中支付方式
    selectPay(item) {
      if (item.type != "daichong") {
        this.payInfo = item;
        this.rchgType = {};
        this.dcPayInfo = {};
        this.payMethodType = "channel";
        this.activeItem = item.type;
      }
      // console.log(item.type)
    },
    // 选中代充支付方式
    selectDcPay(rchgType, dcPayInfo) {
      this.payInfo = {};
      this.rchgType = rchgType;
      this.dcPayInfo = dcPayInfo;
      this.payMethodType = "dc";
      this.activeItem = "dc" + this.dcPayInfo.payMethod;
    },
    // 选中金币兑换
    selectGoldPay() {
      this.payInfo = {};
      this.rchgType = {};
      this.dcPayInfo = {};
      this.payMethodType = "gold";
      this.activeItem = "gold";
    },
    // 金币兑换
    async goldExchange() {
      let req = {
        productType: this.productType,
        productID: this.productID,
        isH5: true,
      };
      try {
        await this.$store.dispatch("user/getWallet");
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = (
          (walletInfo.amount || 0) + (walletInfo.income || 0)
        ).toFixed(2);
        if (this.discountedPrice > walletBalance) {
          Toast("金币不足请充值");
          return;
        }
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch("user/getUserInfo");
          Toast("购买成功");
          this.isRecharge = false;
        } else if (ret.tip) {
          Toast(ret.tip || "购买失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    // 支付渠道支付
    async channelPay() {
      this.$store.commit("app/SET_LOADING", true);

      let req = {
        uid: this.userInfo.uid,
        rechargeType: this.payInfo.type,
        productID: this.productID,
        buyType: this.buyType,
      };
      try {
        let ret = await this.$Api(getPay, req);
        this.payInfo = {};
        this.$store.commit("app/SET_LOADING", false);
        if (ret && ret.code === 200) {
          this.payTipType = "pay";
          this.payUrl = ret.data.payUrl;
          // this.showPayTip = true;
          // this.showKindTips = false;
          setTimeout(() => {
            window.location = ret.data.payUrl;
            this.$store.dispatch("user/getUserInfo");
          }, 200);
        } else {
          Toast(ret.tip || "充值失败, 请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("充值失败, 请稍后再试");
      }
    },
    // 代充
    dcToPay() {
      // let userInfo = this.$store.getters.userInfo;
      let payMoney = this.discountedPrice;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(this.dcPayInfo)); // 代充支付类型
      // console.log(payInfoModel)
      let channel = this.rchgType.channel;
      let token = getLocalItem("token");
      let productInfo = {
        id: this.productID,
        type: this.dcType,
        version: "3.0.0",
      };
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (
        payInfoModel.payType.includes(2) &&
        payInfoModel.payType.includes(3)
      ) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }

      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = channel;
      let models = JSON.stringify(dcData);
      // console.log(dcData)
      let payUrl = `${channel}/?data=${this.encodeBase64(
        models
      )}&token=${token}`;
      // this.showPayTips = true;
      this.showPayTip = true;
      // this.showKindTips = false;
      window.location = payUrl;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    // 获取支付方式名称
    getPayName(type) {
      switch (type) {
        case "alipay":
          return "支付宝";
        case "wechat":
          return "微信";
        case "union":
          return "银联";
        case 101:
          return "支付宝";
        case 102:
          return "微信";
        case 103:
          return "银行卡";
        case 104:
          return "信用卡";
        case 105:
          return "花呗";
        case 106:
          return "云闪付";
        case 107:
          return "QQ钱包";
        case 108:
          return "京东支付";
        default:
          return "金币兑换";
      }
    },
    // 获取支付方式图标
    getSvgPayIcon(type) {
      switch (type) {
        case "alipay":
          return "alipay";
        case "wechat":
          return "wechat";
        case "union":
          return "bankcard";
        case 101:
          return "alipay";
        case 102:
          return "wechat";
        case 103:
          return "bankcard";
        case 104:
          return "bankcard";
        case 105:
          return "alipay";
        case 106:
          return "bankcard";
        case 107:
          return "bankcard";
        case 108:
          return "bankcard";
        default:
          return "gold";
      }
    },
    // 校验有效时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },

    // 处理会员时间展示
    handleVipText() {
      let str = "畅享更多会员专属特权";
      if (this.checkTime(this.userInfo.videoFreeExpiration)) {
        let dateago = new Date(this.userInfo.vipExpireDate);
        let y = dateago.getFullYear();
        let m = dateago.getMonth() + 1;
        let d = dateago.getDate();
        str = `会员到期时间 ${y + "-" + m + "-" + d}`;
      }
      return str;
    },

    closePayTip() {
      (this.payTipType = "tip"),
        (this.payUrl = ""),
        sessionStorage.setItem("firstShowTip", "0");
    },
    payTipBtn() {
      this.showPayTip = false;
      if (this.payTipType == "pay" && this.payUrl) {
        window.location = this.payUrl;
      }
    },
    toPayQuestion() {
      this.showPayTip = false;
      setTimeout(() => {
        this.$router.push("/paymentIssues");
      }, 100);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let currentWorks = vm.$store.state.video.currentWorks;

      vm.$bus.$emit("dataBuriedEvent", {
        dataType: 3,
        graphicsType: vm.$route.query["t"] == "vip" ? 2 : 1,
        graphicsId: currentWorks.id,
        graphicsTitle: currentWorks.title,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.recharge {
  width: 100%;
  height: 100%;
  color: #000;
  background: url("../../../assets/png/gradientBg1.png") no-repeat
    rgb(245, 245, 245);
  background-size: 100% 231px;
  background-position: 0 -50px;

  .navBar {
    height: 44px;
    padding: 0 12px 0;
    // box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #ccbcf6;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 47px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 64px;
      font-size: 14px;
      height: 17px;
      color: #666;
    }
  }

  .userBox {
    margin-top: 18px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .avatarImg {
      width: 50px;
      height: 50px;
      margin-right: 12px;

      /deep/ .vanImage {
        background: #00000000 !important;
      }

      /deep/ .van-image__error {
        background-color: #130f3200 !important;
      }
    }

    .userInfoBox {
      font-size: 12px;

      .uName {
        font-size: 16px;
        display: flex;
        align-items: center;
        .vipIcon {
          width: 61px;
          height: 22px;
          margin-right: 4px;
        }
      }

      .isVip {
        color: #999;
      }
    }
  }

  .vipTab {
    margin-top: 18px;
    // padding: 0 12px;
    // box-sizing: border-box;
    /deep/ .tablist {
      position: relative;
    }
    .tabIcon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .leftTab {
      width: 200px;
      height: 36px;
      padding-right: 10px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rightTab {
      width: 200px;
      height: 36px;
      padding-left: 10px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/ .van-tabs__line {
      display: none;
    }

    /deep/ .van-tab--active {
      //   background: #fff;
      .leftTab {
        background-image: url("../../../assets/png/leftTab.png");
        background-size: 100% 100%;
        z-index: 2;
      }
      .rightTab {
        background-image: url("../../../assets/png/rightTab.png");
        background-size: 100% 100%;
        z-index: 2;
      }
    }

    /deep/ .van-tabs__wrap {
      height: 36px;
      //   border-radius: 23px 0 23px 0;
    }

    /deep/ .van-tab__text {
      font-size: 14px;
    }
    // /deep/ .van-tabs__content {
    //     height: calc(100% -202px) !important;
    // }
  }

  .rechargePop {
    // width: 100%;
    border-radius: 10px 10px 0 0;

    .popBox {
      width: 100%;
      height: 100%;
      background: #fff;
      // background: linear-gradient(180deg, #E5FEFF 0%, #FFF 100%);
      // background: url("../../../assets/png/gradientBg.png") no-repeat;
      // background-size: 100% 100%;

      .rechargeTitle {
        height: 73px;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(33, 33, 33, 0.1);
      }
    }
  }

  .rechargeList {
    max-height: 310px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 18px 36px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    .rechargeItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      .dcIconBox {
        position: relative;

        .official {
          width: 12px;
          height: 12px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .payIcon {
        width: 36px;
        height: 36px;
      }

      .payText {
        flex: 2;
        margin: 0 12px;
      }
    }
  }

  .payTipText {
    padding: 0 36px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    box-sizing: border-box;
  }

  .payTip {
    font-size: 12px;
    padding: 0 36px;
    margin-bottom: 12px;
    color: #999999;
    box-sizing: border-box;
  }

  .customerService {
    color: #8e8d9b;
    font-size: 12px;
    text-align: center;

    span {
      color: #f25477;
    }
  }

  .payBtn {
    width: 297px;
    height: 45px;
    border-radius: 45px;
    font-size: 18px;
    line-height: 45px;
    margin: 12px auto 34px;
    text-align: center;
    color: #424242;
    background: #fbd13d;
  }

  .payTipPop {
    border-radius: 10px;
    width: 300px;
    height: 480px;
    .payTipBox {
      overflow: hidden;
      width: 300px;
      // height: 426px;
      // background: url("../../../assets/png/gradientBg.png") no-repeat;
      // background-size: 100% 50%;
      background-image: linear-gradient(180deg, #fffce0 0%, #fff 30%);
      .payTipTitle {
        font-size: 20px;
        color: #333333;
        text-align: center;
        margin-top: 18px;
        font-weight: 600;
        padding-bottom: 18px;
        border-bottom: solid 1px rgb(230, 230, 230);
      }
      .payTipContent {
        margin-top: 18px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 24px;
        color: #333333;
      }
      .payTipImg {
        width: 256px;
        height: 103px;
        margin: 12px auto 16px;
        background: url("../../../assets/png/payTip.png") no-repeat;
        background-size: 100% 100%;
      }
      .payQuestion {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-bottom: 16px;
        .payQuestionText {
          margin-right: 2px;
          color: #999999;
        }
      }
      .payTipLine {
        width: 100%;
        height: 1px;
        background: #e6e6e6;
      }
      .payTipBtn {
        width: 196px;
        height: 38px;
        text-align: center;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #424242;
        font-size: 14px;
        font-weight: bolder;
        background: #fbd13d;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
